.category_detail {
    display: block;
    flex: 0 1 auto;

    border-right: 1px solid var(--color-header-background);
    border-bottom-right-radius: 7px;

    border-bottom: 1px solid var(--color-header-background);

    font-variant: small-caps;
}

.category_title {
    align-items: center;
    background: var(--color-header-background);
    height: 46px;
    text-align: right;
}

.category_title, .category_description, .category_icon, .category_article_count {
    flex: 0 1 auto;
    display: grid;
    padding: 0 1rem;
    width: 150px;
    text-align: left;
}