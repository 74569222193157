.custom-message {
  width: 100%;
  text-align: center;
}
.loader {
  display: inline-flex;
  border: 4px solid  var(--color-text-primary); 
  border-bottom: 4px solid var(--color-text); 
  border-left: 4px solid var(--color-text-secondary);
  border-top: 4px solid var(--color-text-tertiary); 
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
.loaderText {
  padding-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

