label, input[type=text],select, textarea {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
    line-height: 24px;
}

input[type=text], select, textarea, .editor {
    margin-bottom: 4px;
    width: 100%;
}

textarea {
    height: 100px;
}

.checkboxgroup{
    overflow: auto;
}
.checkboxgroup label{
    width: 12em;
    float: left;
    margin: 0;
    padding: 0;
}
  
.editor {
    background-color: white;
    color: black;
    height: 500px;
}
.contentEditor {
    height: 457px;
}

@media only screen and (max-width: 1200px) {
    /* Small only - phones */
    label, input[type=text], select, textarea, .editor {
        margin-left: 4px;
    }
}

