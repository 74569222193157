.category_list {
    display: block;
    flex: 0 1 auto;

    border-right: 1px solid var(--color-header-background);
    border-bottom-right-radius: 7px;

    border-bottom: 1px solid var(--color-header-background);

    font-variant: small-caps;
    text-align: right;
    transition: all 0.25s ease-out;
}

.category_title {
    display: grid;
    flex: 0 1 auto;
    align-items: center;
    background: var(--color-header-background);
    height: 46px;
    padding: 0 1rem;
    text-align: right;
}
.category_item {
    padding: 0 1rem;
}