.article_list {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px 5px;
}
.article {
    display: flex;
    flex-flow: column;
}
.panel {
    flex-grow: 1;
}
.tall_panel {
    flex-grow: 1;
    grid-column-end: span 2;
    grid-row-end: span 1; 
}

.title {
    display: flex;
    flex: 0 1 auto;
    background: var(--color-header-background);
    height: 46px;
    padding-left: 1rem;  
    align-items:center;
}
.title a {
    text-decoration: none;
    font-weight: normal;
}

.title:hover {
    background-color: var(--color-link);
}

.description {
    display: block;
    flex: 1 1 auto;
    border-left: 1px solid var(--color-header-background);
    padding: 1rem;
}

.categories{
    display: block;
    flex: 0 1 auto;

    border-bottom: 1px solid var(--color-header-background);
    border-left: 1px solid var(--color-header-background);
    border-bottom-left-radius: 7px;

    font-variant: small-caps;
    text-align: right;
    transition: all 0.25s ease-out;

    padding-right: 10px;
}
.category {
    display: inline;
}
.keywords {
    display: inline;
    color: #ff5a00;
    font-size: 0.6rem;
}
.icon {
    width: 100px;
    padding-right: 10px;
    float: left;
}

.options {
    text-align: right;
    padding-right: 10px;
}
.options a, .link {
    color: var(--color-link);    
    text-decoration: none;
    font-size: 0.8rem;
}
.options a:hover, .link:hover {
    color: var(--color-text);    
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    /* Small only - phones */
    .article_list {
        grid-template-columns: auto;        
        margin: 0 4px 0 4px;
    }
    .tall_panel {
        grid-column-end: span 1;
        grid-row-end: span 1;
    }  
}

@media screen and (max-width: 1200px) {
    /* Small only - phones */
    .article_list {
        grid-template-columns: auto;        
        margin: 0 4px 0 4px;
    }
}