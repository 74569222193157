.navigation {
    float: right;
}

header ul {
    list-style: none;
    overflow: hidden;
}
header li {
    float: right;
}
header li::before {
    display: none;
}
header li a {
    display: block;
    padding: 0 0 10px 10px;
}
.nav_list {
    padding: 10px 10px 0 0;
}

.logo {
    float: left;
    padding: 10px 0 0 10px;
}

.hamburger {
    display: none;
    float: right;
    cursor: pointer;
    height: 40px;
    width: 40px;
    margin-top: 5px;
    margin-right: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none; 
}


input[type=search] {
	background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
	border: solid 1px #ccc;
  padding: 2px 3px 3px 32px;
  margin-left: 10px;
	width: 55px;
	
	-webkit-border-radius: 10em;
	-moz-border-radius: 10em;
	border-radius: 10em;
	
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=search]:focus {
	width: 130px;
	background-color: #fff;
	border-color: #66CC75;
	
	-webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
	-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
	box-shadow: 0 0 5px rgba(109,207,246,.5);
}


@media only screen and (max-width: 768px) {
    .navigation_mobile_show {
        display: block !important;
    }
    .navigation {
        width: 100%;
        margin-top: 10px;
        background-color: var(--color-header-background-lighter);
    } 
    .nav_list {
        display: none;
    }
    .nav_item {
        width: 100%;
        padding-left: 15px;
        float: left;
    }
    .hamburger {
        display: block;
    }
}

.waves {
    position:relative;
    width: 100%;
    height: 15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:50px;
    max-height:100px;
  }
/* Animation */

.parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
    }
    h1 {
      font-size:24px;
    }
  }  