:root {
  --color-background: rgb(41, 41, 41, 1);
  --color-background-lighter: rgba(82, 82, 82, 1);

  --color-text: rgb(222, 222, 222);
  --color-text-primary: rgb(41, 41, 41, 1);;
  --color-text-secondary: rgba(33, 131, 196, 1.0); 
  --color-text-tertiary: rgba(209, 0, 174, 1);
  
  --color-link: rgba(209, 0, 174, 1);

  --color-header-background: rgba(33, 131, 196, 1.0);
  --color-header-background-lighter: rgb(95, 155, 196);
  --color-header-background-lightest: rgba(33, 131, 196, 0.25);
  --color-header-text: rgba(255, 255, 255, 1);
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, 'serif';
  font-size: 1.0rem;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}  
h1, h2 {
  font-size: 1.4rem;
}
a {
  color: var(--color-text);
  text-decoration: underline;
  text-decoration-color: var(--color-link);
  font-weight: 400;
}
a:visited {
  font-weight: 400;
}
a:hover {
  text-decoration: underline;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin: 0;
  padding: 0;
}
ol li {
  counter-increment: my-awesome-counter;
}
ol li::before {
  content: counter(my-awesome-counter) ". ";
  color: var(--color-link);
  font-weight: bold;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul li::before {
  content: "o ";
  color: var(--color-link);
  font-weight: bold;
}
pre {
  display: block;
  background-color: var(--color-background-lighter);
  font-family: monospace;
  padding: 15px;
  white-space: pre-wrap;
  overflow-x: auto; 
}

header {
  position: fixed;
  background-color: var(--color-header-background);
  width: 100%;
  top: 0;
  z-index: 3;
}
.container {
  text-align: center;
}

.content {
  position: absolute;
  top: 0;
  flex: 1 0 auto;
  padding-bottom: 40px;
  padding-top: 145px;
  max-width: 1200px;
  margin-left: 210px;
  margin-right: 210px;
}
.leftSidebar {
  position: fixed;
  float: left;
  padding-top: 145px;
  left: 0;
  height: 100%;
}
.rightSidebar {
  float: right;
  padding-top: 150px;
}

footer {
  position: fixed;
  bottom: 0;
  background-color: var(--color-header-background);
  text-align: center;
  width: 100%;
}

.button {
  cursor: pointer;
  display: inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #FFFFFF;
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-weight:300;
  color:#FFFFFF;
  text-align:center;
  transition: all 0.2s;
}

.button:hover {
  color:#000000;
  background-color:#FFFFFF;
}

@media only screen and (max-width: 1200px) {
  /* Small only - phones */
  .leftSidebar, .rightSidebar {
      display: none;
  }
  .content {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  
}
@media only screen and (max-width: 767px) {
  main {
    padding-top: 110px !important;
  }  
}
@media only screen and (maxn-width: 767px) and (orientation: portrait) {
  /* portrait phones */
}

